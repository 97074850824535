import '@babel/polyfill';
import WorkerUtils from 'downzip/src/WorkerUtils';
import Zip from 'downzip/src/zip/Zip';
import ZipUtils from 'downzip/src/zip/ZipUtils';
const Utils = new WorkerUtils('DownZipServiceWorker');
import { Readable } from 'stream-browserify';
let ReadableStreamLocal = (typeof ReadableStream == "undefined") ? Readable : ReadableStream


// /////////// GLOBAL OBJECTS /////////// //
const zipMap = {}

// ////////// MESSAGE HANDLERS ////////// //
const initialize = (data, ports) => {
  Utils.log(`Initialize called: ${JSON.stringify(data)}`);
  console.log("[DownZip]", "Add file called:", data);
  let { id, files, name } = data;
  let isZip = false;
  let ext = "mp4";
  if (name.startsWith("zip|")) {
    isZip = true;
    name = name.replace("zip|", "");
  }
  if (name.startsWith("mp3|")) {
    isZip = false;
    name = name.replace("mp3|", "");
    ext = "mp3";
  }
  if (name.startsWith("mp4|")) {
    isZip = false;
    name = name.replace("mp4|", "");
  }
  if (name.startsWith("webm|")) {
    isZip = false;
    name = name.replace("webm|", "");
    ext = "webm";
  }

  // Decide whether to use zip64
  const totalSizeBig = ZipUtils.calculateSize(files)
  Utils.log(`Total estimated file size: ${totalSizeBig}`)
  const zip64 = (totalSizeBig >= BigInt('0xFFFFFFFF'));

  // Start new Zip object and add to the map
  zipMap[id] = {
    files,
    name,
    zip: isZip ? new Zip(zip64) : null,
    sizeBig: totalSizeBig,
    ext
  }

  // Acknowledge reception
  if (ports.length > 0)
    ports[0].postMessage({ command: 'ACKNOWLEDGE' })
}

// This message is here to keep the service worker from getting killed while downloading.
// TODO: Only send tick while actually downloading
const tick = () => {
  // spellchecker: disable-next-line
  Utils.log(`Tock`)
}

// /////////// EVENT HANDLERS /////////// //
self.addEventListener('install', () => {
  Utils.log("Installing worker and skip waiting");
  console.log("[DownZip]", "Installing worker and skip waiting");
  skipWaiting();
})

self.addEventListener('activate', () => {
  Utils.log("Activating worker and skip waiting");
  console.log("[DownZip]", "Activating worker and skip waiting");
  skipWaiting();
  self.clients.claim();
})

self.addEventListener('fetch', async (event) => {
  // Get URL and check if it is a download request
  Utils.log(`Fetch called for download`, event.request.url);
  if (!event.request.url.includes('downzip/download-'))
    return;
  const urlParts = event.request.url.split('/');
  const lastPart = urlParts[urlParts.length - 1];
  if (lastPart.includes('download-')) {
    // Get download id
    const id = lastPart.replace('download-', '');
    // console.log("id", id, zipMap);
    Utils.log(`Fetch called for download id: ${id}`);

    const item = zipMap[id];

    // Check if initialized
    if (!item) {
      Utils.error(`No zip initialized for id: ${id}`, zipMap);
      return;
    }

    console.log("[DownZip]", "Fetch called for download:", item);

    let outputController = null;
    const outputStream = new ReadableStreamLocal({
      start: (controller) => {
        Utils.log('OutputStream has started!');
        outputController = controller;
      },
      cancel: () => {
        Utils.error('OutputStream has been canceled!')
      },
      read: () => { }
    });
    // Respond with the zip outputStream
    event.respondWith(new Response(
      item.zip ? item.zip.outputStream : outputStream,
      {
        headers: new Headers({
          'Content-Type': 'application/octet-stream; charset=utf-8',
          'Content-Disposition': `attachment; filename="${encodeURIComponent(item.name)}.${item.zip ? "zip" : (item.ext || "mp4")}"`,
          'Content-Length': item.sizeBig  // This is an approximation, does not take into account the headers
        })
      }
    ));

    // Start feeding zip the downloads
    for (let i = 0; i < item.files.length; i++) {
      const file = item.files[i];

      // Start new file in the zip
      if (item.zip)
        item.zip.startFile(file.name);

      // Append all the downloaded data
      try {
        await new Promise((resolve, reject) => {
          fetch(file.downloadUrl).then(response => response.body).then(async (stream) => {
            const reader = stream.getReader();
            let doneReading = false;
            while (!doneReading) {
              const chunk = await reader.read();
              const { done, value } = chunk;

              if (done) {
                // If this stream has finished, resolve and return
                resolve();
                doneReading = true;
              } else {
                // If not, append data to the zip
                if (item.zip)
                  item.zip.appendData(value);
                else
                  outputController.enqueue(value);
              }
            }
          }).catch((err) => {
            console.error("[DownZip]", "Error while piping data into file:", err);
            reject(err);
          })
        })
      } catch (e) {
        Utils.error(`Error while piping data into zip: ${e.toString()}`);
      }

      // End file
      if (item.zip)
        item.zip.endFile();
    }

    // End zip
    if (item.zip) {
      item.zip.finish();
      const zip64 = item.sizeBig >= BigInt('0xFFFFFFFF');
      item.zip = new Zip(zip64);
    }
    else
      outputController.close();
    Utils.log("Done with this zip!")
  } else {
    Utils.log('Fetch called for a non-download. Doing nothing')
  }
})

self.addEventListener('error', (message, url, lineNo) => {
  Utils.log(`Error: ${message} at line number: ${lineNo}. Handling URL ${url}`)
  return true
})

const messageHandlers = {
  'INITIALIZE': initialize,
  'TICK': tick
}
self.addEventListener('message', async (event) => {
  const { data, ports } = event;
  if (!data?.command)
    return;
  if (data?.source === "react-devtools-content-script")
    return;
  // console.log("message", data.command);
  const handler = messageHandlers[data.command]
  if (handler) {
    await handler(data.data, ports)
  } else {
    Utils.error(`Handler for command does not exist!!!: ${data.command}`)
  }
})